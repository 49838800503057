import Axios from "axios"
import { useUser } from "hooks"
import { CountryCode } from "libphonenumber-js"
import { useEffect, useState } from "react"

const defaultCountry = "CA"

export function useUserCountry() {
  const { user, isFetched } = useUser()
  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState<CountryCode | undefined>()

  useEffect(() => {
    loadUserLocation()
  }, [])

  async function loadUserLocation() {
    const { data } = await Axios.get(`/api/utils/getUserCountryFromIp`)

    setCountry(data.country ? data.country : defaultCountry)
    setLoading(false)
  }

  return {
    loading,
    country:
      isFetched && user?.majorityOfClientsCountryCode
        ? user?.majorityOfClientsCountryCode
        : country,
  }
}

// Will be used in bookings no need to have a logged in user
export function useVisitorCountry() {
  const [country, setCountry] = useState<CountryCode | string>("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadUserLocation()
  }, [])

  async function loadUserLocation() {
    const { data } = await Axios.get(`/api/utils/getUserCountryFromIp`)

    setCountry(data.country ? data.country : defaultCountry)
    setLoading(false)
  }

  return {
    loading,
    country: country,
  }
}
