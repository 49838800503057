import { cn } from "lib/shadcnUtils"
import { Loader2 } from "lucide-react"

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number
  applySecondaryColor?: boolean
}

export function Spinner({
  size = 32,
  className,
  applySecondaryColor = false,
  ...props
}: SpinnerProps) {
  return (
    <div
      role="status"
      className={cn(" text inline-block text-indigo-600", className)}
      style={applySecondaryColor ? { color: "rgb(59, 195, 211)" } : {}}
      {...props}
    >
      <Loader2 className="animate-spin" size={size} />
      <span className="sr-only">Loading...</span>
    </div>
  )
}
